import AuthService from "../components/AuthService";
import DBService, { ResourceName } from "../components/DBService";
import {
  Accommodation,
  Alarm,
  Asset,
  AwarenessScreen,
  Client,
  Consumable,
  Device,
  GenericFunction,
  Model,
  PublicAwarenessScreen,
  Sensor,
} from "../components/Interfaces";
import EnvVars from "./EnvVars";
import { cloneObject, isStrictlyDefined, StringMap } from "./Helpers";

abstract class AuthServicePort {
  static isAuthenticated: () => boolean;
}
abstract class BackendServicePort {
  static getData: <T>(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) => Promise<T | undefined>;
}

export class DroopleCache {
  static authServicePort: typeof AuthServicePort;
  static backendServicePort: typeof BackendServicePort;
  static data: StringMap<any> = {};

  /**
   * Because AuthService and DBService use DroopleCache, we need to delay the port configuration using an init()
   * function that we call in App.tsx at first load. Otherwise, we have an unresolved circular dependency
   * throwing ReferenceError: Cannot access AuthService before initialization.
   */
  static init(
    authServicePort: typeof AuthServicePort = AuthService,
    backendServicePort: typeof BackendServicePort = DBService
  ) {
    DroopleCache.authServicePort = authServicePort;
    DroopleCache.backendServicePort = backendServicePort;
    DroopleCache.data = {};
  }

  /**
   * Invalidate cached values.
   *
   * If some keys are specified, only invalidate the associated caches
   * @param keys optional keys to invalidate
   */
  static invalidate(...keys: string[]) {
    if (keys.length > 0) {
      keys.forEach((key) => delete DroopleCache.data[key]);
    } else {
      DroopleCache.data = {};
    }
  }

  /**
   * Call function `func` with parameters `args`. Store the results in the cache under the key `key`.
   *
   * If the key already exists, return a new copy of the result stored under this key.
   */
  static get<F extends GenericFunction>(
    key: string,
    func: F,
    ...args: Parameters<F>
  ): ReturnType<F> {
    if (DroopleCache.data[key] === undefined) {
      DroopleCache.data[key] = func(...args);
    } else {
      console.log(`Using cached value for key ${key}`);
    }

    const cachedValue = DroopleCache.data[key];
    // if it's a promise, we clone the result from the promise, not the promise itself
    if (cachedValue && cachedValue.then) {
      return Promise.resolve(cachedValue).then((val: any) =>
        val ? cloneObject(val) : val
      ) as ReturnType<F>;
    } else {
      return cachedValue ? cloneObject(cachedValue) : cachedValue;
    }
  }

  /**
   * Loads the entire cache sequentially, in order of priority, anticipating the fact that the user will visit other pages
   * and therefore reducing latency while browsing.
   *
   * We use sequential loading to spread the load on the backend.
   */
  static async loadEverything() {
    console.log("Caching Droople entities...");
    const loaders = [
      DroopleCache.getAssets,
      DroopleCache.getDevices,
      DroopleCache.getClients,
      DroopleCache.getClientsWithCounts,
      DroopleCache.getConsumables,
      DroopleCache.getAlarms,
      DroopleCache.getModels,
      DroopleCache.getSensors,
      DroopleCache.getAccommodations,
    ];
    for (const loader of loaders) {
      if (DroopleCache.authServicePort.isAuthenticated()) {
        await loader();
      }
      if (!DroopleCache.authServicePort.isAuthenticated()) {
        // we separate this test here because the logout could have been caused while loader() was running
        console.log(
          "User not authenticated anymore. Aborting cache load and invalidating data."
        );
        DroopleCache.invalidate();
        return false;
      }
    }
    console.log("Cache has been completely loaded.");
    return true;
  }

  static getClientsWithCounts() {
    return DroopleCache.get(
      "clients-with-counts",
      DroopleCache.backendServicePort.getData,
      "clients?withCounts=true"
    ) as Promise<Client[] | undefined>;
  }

  static getClients() {
    return DroopleCache.get(
      "clients",
      DroopleCache.backendServicePort.getData,
      "clients"
    ) as Promise<Client[] | undefined>;
  }

  static getAssets() {
    return DroopleCache.getAllPages(
      EnvVars.ASSETS_TO_FETCH,
      EnvVars.ASSETS_PAGE_SIZE,
      (index: number) => DroopleCache.getAssetsPage(index)
    );
  }

  static getAssetsPage(index: number, page_size?: number) {
    const limit = page_size ?? EnvVars.ASSETS_PAGE_SIZE;
    const offset = index * (page_size ?? EnvVars.ASSETS_PAGE_SIZE);
    return DroopleCache.get(
      `assets-limit-${limit}-offset-${offset}`,
      DroopleCache.backendServicePort.getData,
      `assets?limit=${limit}&offset=${offset}`
    ) as Promise<Asset[] | undefined>;
  }

  static getClientAssets(clientID: number) {
    return DroopleCache.getAllPages(
      EnvVars.ASSETS_TO_FETCH,
      EnvVars.ASSETS_PAGE_SIZE,
      (index: number) => DroopleCache.getClientAssetsPage(clientID, index)
    );
  }

  static getClientAssetsPage(
    clientID: number,
    index: number,
    page_size?: number
  ) {
    const limit = page_size ?? EnvVars.ASSETS_PAGE_SIZE;
    const offset = index * (page_size ?? EnvVars.ASSETS_PAGE_SIZE);
    return DroopleCache.get(
      `client-assets-${clientID}-limit-${limit}-offset-${offset}`,
      DroopleCache.backendServicePort.getData,
      "clients",
      clientID,
      `assets?limit=${limit}&offset=${offset}`
    ) as Promise<Asset[] | undefined>;
  }

  static async getConsumablesCount() {
    return (
      await (DroopleCache.get(
        `consumable-count`,
        DroopleCache.backendServicePort.getData,
        `consumables_count`
      ) as Promise<{ count: number }>)
    ).count;
  }

  static async getConsumables() {
    return DroopleCache.getAllPages(
      await DroopleCache.getConsumablesCount(),
      EnvVars.CONSUMABLE_PAGE_SIZE,
      (index: number) => DroopleCache.getConsumablesPage(index)
    );
  }

  static getConsumablesPage(index: number) {
    const limit = EnvVars.CONSUMABLE_PAGE_SIZE;
    const offset = index * limit;
    return DroopleCache.get(
      `consumable-limit-${limit}-offset-${offset}`,
      DroopleCache.backendServicePort.getData,
      `consumables?limit=${limit}&offset=${offset}`
    ) as Promise<Consumable[] | undefined>;
  }

  static getDevices() {
    return DroopleCache.getAllPages(
      EnvVars.DEVICES_TO_FETCH,
      EnvVars.DEVICES_PAGE_SIZE,
      (index: number) => DroopleCache.getDevicesPage(index)
    );
  }

  static getDevicesPage(index: number, page_size?: number) {
    const limit = page_size ?? EnvVars.DEVICES_PAGE_SIZE;
    const offset = index * (page_size ?? EnvVars.DEVICES_PAGE_SIZE);
    return DroopleCache.get(
      `devices-limit-${limit}-offset-${offset}`,
      DroopleCache.backendServicePort.getData,
      `devices?limit=${limit}&offset=${offset}`
    ) as Promise<Device[] | undefined>;
  }

  static getSensors() {
    return DroopleCache.get(
      "sensors",
      DroopleCache.backendServicePort.getData,
      "sensors"
    ) as Promise<Sensor[] | undefined>;
  }

  static getModels() {
    return DroopleCache.get(
      "models",
      DroopleCache.backendServicePort.getData,
      "models"
    ) as Promise<Model[] | undefined>;
  }

  static getAccommodations() {
    return DroopleCache.get(
      "accommodations",
      DroopleCache.backendServicePort.getData,
      "accommodations"
    ) as Promise<Accommodation[] | undefined>;
  }

  static getAlarms() {
    return DroopleCache.get(
      "alarms",
      DroopleCache.backendServicePort.getData,
      "alarms"
    ) as Promise<Alarm[] | undefined>;
  }

  static async getPublicAwarenessScreens(): Promise<
    Array<PublicAwarenessScreen>
  > {
    return DroopleCache.get(
      "awareness_screen/public",
      DroopleCache.backendServicePort.getData,
      "awareness_screen/public"
    ) as Promise<Array<PublicAwarenessScreen>>;
  }

  static async getAwarenessScreens(): Promise<Array<AwarenessScreen>> {
    return DroopleCache.get(
      "awareness_screens",
      DroopleCache.backendServicePort.getData,
      "awareness_screens"
    ) as Promise<Array<AwarenessScreen>>;
  }

  static getAllPages<
    T extends object | string | number | bigint | boolean | symbol
  >(
    totalResults: number,
    pageSize: number,
    getPage: (index: number) => Promise<T[] | undefined>
  ) {
    const total_pages = Math.ceil(totalResults / pageSize);
    const pages_indices = [...Array(total_pages).keys()]; // = [0, 1, 2, ...]
    return Promise.all(pages_indices.map(getPage)).then(
      (_) => _.flat().filter(isStrictlyDefined) as T[]
    );
  }
}
